import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  CardActions,
  Grid
} from "@material-ui/core"
import { Auth } from "aws-amplify"
import { navigate } from "gatsby"

import okImg from "../../assets/undraw/undraw_payments_21w6.svg"

const styles = (theme: Theme) =>
  createStyles({
    card: {
      width: "450px"
    }
  })

interface StripeSuccessProps extends WithStyles<typeof styles> {}

const StripeSuccess = (props: StripeSuccessProps) => {
  React.useEffect(() => {
    //below invalidates the token so that the student can get the data
    ;(async () => {
      const currentUser = await Auth.currentAuthenticatedUser()
      const userSession = await currentUser.getSignInUserSession()
      const refreshToken = userSession.getRefreshToken()
      currentUser.refreshSession(refreshToken, (err, session) => {
        currentUser.setSignInUserSession(session)
      })
    })()
  }, [])

  const { classes } = props
  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
      <Grid item xs={3}>
        <Card className={classes.card}>
          <CardActionArea onClick={() => navigate("/course")}>
            <CardMedia component="img" alt="Payment Succeeded" height="260" image={okImg} title="Start Course" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Payment Succeeded
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Thank you for your purchase. We hope you enjoy your jurney on mastering the ESP-IDF
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => navigate("/course")}>
              Start Course
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(StripeSuccess)
